import axios from "./client";
import { VehicleActions } from "../reducers/VehicleReducer";
import store from "./../store";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchVehicles = async () => {
	try {
		store.dispatch({ type: VehicleActions.RequestVehicles, payload: {} });
		const { data: vehicles } = await axios.get<Vehicle[]>(`${API_URL}vehicles`);
		store.dispatch({
			type: VehicleActions.ReceiveVehicles,
			payload: vehicles,
		});
	} catch (error) {
		console.error("Error al obtener vehículos:", error);
		store.dispatch({
			type: VehicleActions.ReceiveVehicles,
			payload: [],
		});
	}
};

export const deleteVehicle = async (id: string) => {
	try {
		const { data: vehicle } = await axios.delete<Vehicle>(
			`${API_URL}vehicles/${id}`
		);
		store.dispatch({
			type: VehicleActions.DeleteVehicle,
			payload: vehicle,
		});
		return vehicle;
	} catch (error) {
		console.error("Error al eliminar vehículo:", error);
		throw error;
	}
};

export const updateVehicle = async (id: string, vehicle: Partial<Vehicle>) => {
	try {
		const { data: editedVehicle } = await axios.patch<Vehicle>(
			`${API_URL}vehicles/${id}`,
			vehicle
		);
		store.dispatch({
			type: VehicleActions.UpdateVehicle,
			payload: editedVehicle,
		});
		return editedVehicle;
	} catch (error) {
		console.error("Error al actualizar vehículo:", error);
		throw error;
	}
};

export const addVehicle = async (vehicle: Partial<Vehicle>) => {
	try {
		const { data: newVehicle } = await axios.post<Vehicle>(
			`${API_URL}vehicles/`,
			vehicle
		);
		store.dispatch({
			type: VehicleActions.AddVehicle,
			payload: newVehicle,
		});
		return newVehicle;
	} catch (error) {
		console.error("Error al agregar vehículo:", error);
		throw error;
	}
};
