import { createTheme } from "@mui/material/styles";
import { CSSObject } from "@mui/system";

const theme = createTheme({
	// COLORES PRINCIPALES
	palette: {
		background: {
			default: "#E5E5E5",
		},
		primary: {
			main: "#003755",
		},
		secondary: {
			main: "#FF8C00",
		},
	},

	typography: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
	},

	// PERSONALIZACIÓN DE COMPONENTES INDIVIDUALES
	components: {
		// BARRA SUPERIOR
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: "#003755",
					boxShadow: "none",
					padding: "0 16px",
				},
			},
		},

		MuiToolbar: {
			styleOverrides: {
				root: {
					minHeight: "64px",
					display: "flex",
					justifyContent: "space-between",
					padding: "0 16px",
				},
			},
		},

		// MENÚ LATERAL
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: "#003755",
					color: "#fff",
					width: 240,
				},
			},
		},

		MuiMenuItem: {
			styleOverrides: {
				root: {
					padding: "8px 16px",
					"&:hover": {
						backgroundColor: "#FF8C00",
					},
				},
			},
		},

		MuiListItemText: {
			styleOverrides: {
				primary: {
					fontWeight: 600,
					color: "#fff",
				},
			},
		},

		// TABLA
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: "none",
					fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',

					// ...({}) as CSSObject es para que TypeScript no se queje con los selectores CSS anidados
					...({
						"& .MuiDataGrid-iconButtonContainer": {
							color: "#FF8C00",
						},
						"& .MuiDataGrid-sortIcon": {
							color: "#FF8C00",
						},
						"& .MuiDataGrid-filterIcon": {
							color: "#FF8C00",
						},

						"& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-columnHeaderTitle":
							{
								fontWeight: "700",
							},
						"& .MuiDataGrid-columnHeader--filtered .MuiDataGrid-columnHeaderTitle":
							{
								fontWeight: "700",
							},

						"& .MuiIconButton-root:hover": {
							backgroundColor: "rgba(255, 140, 0, 0.1)",
						},

						"& .MuiDataGrid-columnHeaderTitle": {
							textAlign: "center",
							width: "100%",
						},
						"& .MuiDataGrid-cellContent": {
							textAlign: "center",
							width: "100%",
						},
						"& .MuiDataGrid-columnHeader": {
							justifyContent: "center",
							padding: "0 8px",
						},

						"& .MuiDataGrid-columnHeaderTitleContainer": {
							justifyContent: "center",
							gap: "4px",
						},

						"& .MuiDataGrid-columnHeaderTitleContainerContent": {
							justifyContent: "center",
						},
					} as CSSObject),
				},

				columnHeaders: {
					backgroundColor: "#003755",
					color: "#fff",
					fontWeight: 600,
					borderBottom: "none",
				},

				cell: {
					borderBottom: "none",
					padding: "8px",
				},

				row: {
					"&:nth-of-type(odd)": {
						backgroundColor: "#f9f9f9",
					},
				} as any,

				footerContainer: {
					borderTop: "1px solid rgba(0, 0, 0, 0.12)",
				},
			},
		},

		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: 16,
					boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
					backgroundColor: "#ffffff",
				},
			},
		},

		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: "16px",
				},
			},
		},

		MuiCardActions: {
			styleOverrides: {
				root: {
					padding: "8px 16px",
					justifyContent: "flex-end",
					backgroundColor: "#f9f9f9",
				},
			},
		},

		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: 16,
					padding: 0,
				},
			},
		},

		MuiDialogTitle: {
			styleOverrides: {
				root: {
					padding: "16px",
					fontSize: "1.25rem",
					fontWeight: 600,
					backgroundColor: "#003755",
					color: "#fff",
				},
			},
		},

		MuiDialogContent: {
			styleOverrides: {
				root: {
					padding: "16px",
				},
			},
		},

		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: "16px",
					justifyContent: "flex-end",
				},
			},
		},

		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: 8,

					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: "rgba(0,0,0,0.23)",
					},

					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: "#003755",
					},

					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderColor: "#388e3c",
						borderWidth: "2px",
					},
				},

				input: {
					padding: "12px",
				},
			},
		},

		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontWeight: 500,
					color: "#003755",

					"&.Mui-focused": {
						color: "#388e3c",
					},
				},
			},
		},

		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 8,
					textTransform: "none",
					padding: "8px 16px",
					fontWeight: 600,
					transition: "background-color 0.3s, box-shadow 0.3s",

					"&:hover": {
						backgroundColor: "#FF8C00",
						boxShadow: "0px 3px 5px rgba(0,0,0,0.2)",
					},

					"&.Mui-disabled": {
						backgroundColor: "rgba(0,0,0,0.12)",
						color: "rgba(0,0,0,0.26)",
					},
				},
			},
		},
	},
});

export default theme;
