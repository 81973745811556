import React, { useEffect, useState } from "react";
import {
	DataGrid,
	GridRenderCellParams,
	GridColDef,
	esES,
} from "@mui/x-data-grid";
import { Button, Card, Box, Typography, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppSelector } from "../../../hooks/storeHooks";
import { fetchAddons, deleteAddon } from "../../../services/addonService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import NewAddonModal from "./NewAddonModal";

const AddonsView: React.FC = () => {
	const theme = useTheme();
	const { isLoading, addons } = useAppSelector((state) => state.addonReducer);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedAddon, setSelectedAddon] = useState<Addon | null>(null);

	const columns: GridColDef[] = [
		{
			field: "plate",
			minWidth: 150,
			flex: 1,
			headerName: "Patente",
			headerAlign: "center",
			align: "center",
		},
		{
			field: "addonType",
			minWidth: 150,
			flex: 1,
			headerName: "Tipo de Remolque",
			headerAlign: "center",
			align: "center",
			renderCell: (params: GridRenderCellParams) => (
				<Typography variant="body2">{params.row.addonType || "-"}</Typography>
			),
		},
		{
			field: "edit",
			minWidth: 100,
			flex: 1,
			headerName: "Editar",
			headerAlign: "center",
			align: "center",
			renderCell: (params: GridRenderCellParams) => (
				<Button
					variant="contained"
					startIcon={<EditIcon />}
					sx={{
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.common.white,
						"&:hover": { backgroundColor: theme.palette.primary.dark },
					}}
					onClick={() => {
						const addonToEdit = addons.find(
							(addon) => addon._id === params.row._id
						);
						if (addonToEdit) {
							setSelectedAddon(addonToEdit);
							setModalOpen(true);
						}
					}}
				>
					Editar
				</Button>
			),
		},
		{
			field: "delete",
			minWidth: 150,
			flex: 1,
			headerName: "Borrar",
			headerAlign: "center",
			align: "center",
			renderCell: (params: GridRenderCellParams) => (
				<Button
					variant="contained"
					startIcon={<DeleteIcon />}
					sx={{
						backgroundColor: theme.palette.error.main,
						color: theme.palette.common.white,
						"&:hover": { backgroundColor: theme.palette.error.dark },
					}}
					onClick={() => {
						deleteAddon(params.row._id);
					}}
				>
					Borrar
				</Button>
			),
		},
	];

	useEffect(() => {
		fetchAddons();
	}, []);

	return (
		<Box sx={{ p: theme.spacing(3), height: "100%", overflow: "auto" }}>
			<Typography variant="h4" sx={{ mb: theme.spacing(3) }}>
				Semi Remolques
			</Typography>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					mb: theme.spacing(2),
				}}
			>
				<Button
					variant="contained"
					onClick={() => {
						setSelectedAddon(null);
						setModalOpen(true);
					}}
				>
					Agregar
				</Button>
			</Box>
			<Box>
				{isLoading ? (
					<Box
						sx={{ display: "flex", justifyContent: "center", width: "100%" }}
					>
						<Icon spin path={mdiCircleSlice1} className="w-7" />
					</Box>
				) : (
					<Card>
						<DataGrid
							sx={{ p: theme.spacing(2) }}
							autoHeight
							columns={columns}
							rows={addons}
							pageSize={20}
							getRowId={(row) => row._id}
							localeText={esES.components.MuiDataGrid.defaultProps.localeText}
						/>
						<NewAddonModal
							open={modalOpen}
							onClose={() => setModalOpen(false)}
							addon={selectedAddon}
							setAddon={setSelectedAddon}
						/>
					</Card>
				)}
			</Box>
		</Box>
	);
};

export default AddonsView;
