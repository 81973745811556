import React, { useCallback, useEffect, useState } from "react";
import {
	DataGrid,
	GridRenderCellParams,
	GridColDef,
	esES,
	GridRowModel,
} from "@mui/x-data-grid";
import {
	Alert,
	AlertProps,
	Button,
	Card,
	MenuItem,
	Snackbar,
	TextField,
	Box,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useAppSelector } from "../../../hooks/storeHooks";
import {
	fetchTrips,
	generateTripPDF,
	updateTrip,
} from "../../../services/tripService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import DocumentsModal from "./DocumentsModal";
import { fetchDrivers } from "../../../services/driverService";
import { fetchVehicles } from "../../../services/vehicleService";
import { filterTrips } from "../../../utils/filterTrips";
import handleGenerateExcel from "../../../utils/createTripsExcel";


const TripsView: React.FC = () => {
	const theme = useTheme();
	const { isLoading, trips } = useAppSelector(
		(state: any) => state.tripReducer
	);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedPhotos, setSelectedPhotos] = useState<string[] | null>(null);
	const [filteredTrips, setFilteredTrips] = useState<Trip[]>([]);
	const [snackbar, setSnackbar] = useState<Pick<
		AlertProps,
		"children" | "severity"
	> | null>(null);

	const [filterOptions, setFilterOptions] = useState({
		fromDate: null as Date | null,
		toDate: null as Date | null,
		customerNameFilter: "",
		transportTypeFilter: "todos",
		applyDateFilter: false,
	});

	const applyFilter = (name: string, value: any, additionalOptions = {}) => {
		setFilterOptions((prev) => {
			const newOptions = {
				...prev,
				[name]: value,
				...additionalOptions,
			};

			if (trips) {
				setFilteredTrips(filterTrips(trips, newOptions));
			}

			return newOptions;
		});
	};

	const handleCustomerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		applyFilter("customerNameFilter", e.target.value);
	};

	const handleTransportTypeChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		applyFilter("transportTypeFilter", e.target.value);
	};

	const handleFromDateChange = (newValue: Date | null) => {
		applyFilter(
			"fromDate",
			newValue,
			newValue && filterOptions.toDate ? { applyDateFilter: true } : {}
		);
	};

	const handleToDateChange = (newValue: Date | null) => {
		applyFilter(
			"toDate",
			newValue,
			newValue && filterOptions.fromDate ? { applyDateFilter: true } : {}
		);
	};

	const clearFilters = () => {
		const resetOptions = {
			fromDate: null,
			toDate: null,
			customerNameFilter: "",
			transportTypeFilter: "todos",
			applyDateFilter: false,
		};

		setFilterOptions(resetOptions);
		setFilteredTrips(trips || []);
	};

	const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
		const response = await updateTrip(newRow._id, {
			observations: newRow.observations,
			transportDocument: newRow.transportDocument,
			tripNumber: newRow.tripNumber,
		});

		// After successful update, apply current filters to the updated trips
		if (trips) {
			const updatedTrips = trips.map((trip: Trip) =>
				trip._id === newRow._id ? { ...trip, ...newRow } : trip
			);
			setFilteredTrips(filterTrips(updatedTrips, filterOptions));
		}

		setSnackbar({ children: "Viaje actualizado", severity: "success" });
		return response;
	}, [trips, filterOptions]);

	const handleCloseSnackbar = () => setSnackbar(null);

	const handleProcessRowUpdateError = useCallback((error: Error) => {
		setSnackbar({ children: error.message, severity: "error" });
	}, []);

	// Definición de columnas básicas
	const columns: GridColDef[] = [
		{
			field: "driver",
			minWidth: 250,
			flex: 1,
			headerName: "Nombre Conductor",
			headerAlign: "center",
			align: "center",
			valueGetter(params) {
				if (params.row.driver !== undefined && params.row.driver !== null) {
					return params.row.driver.name;
				} else {
					return "Conductor Eliminado";
				}
			},
			renderCell: (params: GridRenderCellParams) => {
				if (params.row.driver === null) {
					return <Typography variant="body2">Conductor eliminado</Typography>;
				} else {
					return (
						<Typography variant="body2">{params.row.driver.name}</Typography>
					);
				}
			},
		},
		{
			field: "shiftType",
			minWidth: 130,
			flex: 1,
			headerName: "Tipo de Turno",
			headerAlign: "center",
			align: "center",
			valueGetter: (params) => params.row.shiftType || "-",
			renderCell: (params: GridRenderCellParams) => (
				<Typography variant="body2">{params.row.shiftType || "-"}</Typography>
			),
		},
		{
			field: "tripDirection",
			minWidth: 150,
			flex: 1,
			headerName: "Dirección",
			headerAlign: "center",
			align: "center",
			valueGetter: (params) => params.row.tripDirection || "-",
			renderCell: (params: GridRenderCellParams) => (
				<Typography variant="body2">
					{params.row.tripDirection || "-"}
				</Typography>
			),
		},
		{
			field: "origin",
			minWidth: 150,
			flex: 1,
			headerName: "Origen",
			headerAlign: "center",
			align: "center",
		},
		{
			field: "destination",
			minWidth: 150,
			flex: 1,
			headerName: "Destino",
			headerAlign: "center",
			align: "center",
		},
		{
			field: "transportType",
			minWidth: 150,
			flex: 1,
			headerName: "Tipo de Transporte",
			headerAlign: "center",
			align: "center",
			valueGetter: (params) => params.row.transportType || "-",
		},
		{
			field: "vehicleMachineryType",
			minWidth: 180,
			flex: 1,
			headerName: "Tipo de Vehículo",
			headerAlign: "center",
			align: "center",
			valueGetter: (params) => params.row.vehicleMachineryType || "-",
		},
		{
			field: "vehiclePlate",
			minWidth: 150,
			flex: 1,
			headerName: "Patente",
			headerAlign: "center",
			align: "center",
		},
		{
			field: "addonPlate",
			minWidth: 150,
			flex: 1,
			headerName: "Patente Semi Remolque",
			headerAlign: "center",
			align: "center",
		},
		{
			field: "addonType",
			minWidth: 180,
			flex: 1,
			headerName: "Tipo Semi Remolque",
			headerAlign: "center",
			align: "center",
		},
		{
			field: "transportDocument",
			minWidth: 180,
			flex: 1,
			editable: true,
			headerName: "Documento de Transporte",
			headerAlign: "center",
			align: "center",
		},
		{
			field: "tripNumber",
			minWidth: 180,
			flex: 1,
			editable: true,
			headerName: "Numero de Viaje",
			headerAlign: "center",
			align: "center",
		},
		{
			field: "customer",
			minWidth: 150,
			flex: 1,
			headerName: "Cliente",
			headerAlign: "center",
			align: "center",
			valueGetter(params) {
				const customer = params.row.customer;
				if (!customer) return "";
				return customer.branch
					? `${customer.name} - ${customer.branch}`
					: customer.name;
			},
			renderCell: (params: GridRenderCellParams) => {
				const customer = params.row.customer;
				if (!customer)
					return <Typography variant="body2">Cliente eliminado</Typography>;
				return (
					<Typography variant="body2">
						{customer.branch
							? `${customer.name} - ${customer.branch}`
							: customer.name}
					</Typography>
				);
			},
		},
		{
			field: "programmedAt",
			minWidth: 150,
			flex: 1,
			headerName: "Fecha Programada",
			type: "dateTime",
			headerAlign: "center",
			align: "center",
			valueFormatter(params) {
				const date = new Date(params.value);
				return `${date.getDate()}/${date.getMonth() + 1
					}/${date.getFullYear()} `;
			},
		},
		{
			field: "programmedHourAt",
			minWidth: 150,
			flex: 1,
			headerName: "Hora Programada",
			headerAlign: "center",
			align: "center",
			valueGetter(params) {
				const date = new Date(params.row.programmedAt);
				const minutes = date.getMinutes();
				const hours = date.getHours();
				return `${hours}:${minutes.toString().padStart(2, "0")}`;
			},
		},
		{
			field: "document",
			minWidth: 150,
			flex: 1,
			headerName: "Documento",
			headerAlign: "center",
			align: "center",
			renderCell: (params: GridRenderCellParams) => {
				if (params.row.photos && params.row.photos.length > 0) {
					return (
						<Button
							variant="contained"
							onClick={() => {
								setSelectedPhotos(params.row.photos);
								setModalOpen(true);
							}}
						>
							Abrir
						</Button>
					);
				} else {
					return <Typography variant="body2" />;
				}
			},
		},
		{
			field: "pdfDownload",
			minWidth: 150,
			flex: 1,
			headerName: "Descargar",
			headerAlign: "center",
			align: "center",
			renderCell: (params: GridRenderCellParams) => {
				if (params.row.photos && params.row.photos.length > 0) {
					return (
						<Button
							variant="contained"
							onClick={() => generateTripPDF(params.row._id, params.row.tripNumber)}
						>
							Descargar
						</Button>
					);
				} else {
					return <Typography variant="body2" />;
				}
			},
		},
		{
			field: "observations",
			minWidth: 150,
			flex: 1,
			headerName: "Observaciones",
			editable: true,
			headerAlign: "center",
			align: "center",
		},
	];

	useEffect(() => {
		fetchDrivers();
		fetchTrips();
		fetchVehicles();
	}, []);

	useEffect(() => {
		if (trips && trips.length > 0) {
			setFilteredTrips(trips);
		}
	}, [trips]);

	return (
		<Box sx={{ p: theme.spacing(3), height: "100%", overflow: "auto" }}>
			<Typography variant="h4" sx={{ mb: theme.spacing(3) }}>
				Trayectos
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", sm: "row" },
					flexWrap: "wrap",
					gap: theme.spacing(2),
					m: theme.spacing(3),
				}}
			>
				<TextField
					label="Cliente"
					placeholder="Buscar por nombre"
					value={filterOptions.customerNameFilter}
					onChange={handleCustomerNameChange}
					size="medium"
					sx={{ minWidth: 200 }}
				/>

				<TextField
					select
					label="Tipo de Transporte"
					value={filterOptions.transportTypeFilter}
					onChange={handleTransportTypeChange}
					sx={{ minWidth: 200 }}
				>
					<MenuItem value="todos">Todos</MenuItem>
					<MenuItem value="pasajeros">Pasajeros</MenuItem>
					<MenuItem value="carga">Carga</MenuItem>
				</TextField>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DesktopDatePicker
						label="Desde"
						inputFormat="dd/MM/yyyy"
						value={filterOptions.fromDate}
						onChange={handleFromDateChange}
						renderInput={(params) => <TextField {...params} />}
					/>
				</LocalizationProvider>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DesktopDatePicker
						label="Hasta"
						inputFormat="dd/MM/yyyy"
						value={filterOptions.toDate}
						onChange={handleToDateChange}
						renderInput={(params) => <TextField {...params} />}
					/>
				</LocalizationProvider>
				<Button variant="outlined" color="info" onClick={clearFilters}>
					Limpiar filtros
				</Button>
				<Button variant="contained" onClick={() => handleGenerateExcel(filteredTrips)}>
					Exportar a Excel
				</Button>
			</Box>
			<Box>
				{isLoading ? (
					<Box
						sx={{ display: "flex", justifyContent: "center", width: "100%" }}
					>
						<Icon spin path={mdiCircleSlice1} className="w-7" />
					</Box>
				) : (
					<Card>
						<DataGrid
							sx={{ p: theme.spacing(2) }}
							autoHeight
							columns={columns}
							rows={filteredTrips}
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							getRowId={(row) => row._id}
							localeText={esES.components.MuiDataGrid.defaultProps.localeText}
							processRowUpdate={processRowUpdate}
							experimentalFeatures={{ newEditingApi: true }}
							onProcessRowUpdateError={handleProcessRowUpdateError}
						/>
					</Card>
				)}
			</Box>
			{snackbar && (
				<Snackbar
					open
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					onClose={handleCloseSnackbar}
					autoHideDuration={6000}
				>
					<Alert {...snackbar} onClose={handleCloseSnackbar} />
				</Snackbar>
			)}
			<DocumentsModal
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				photos={selectedPhotos}
			/>
		</Box>
	);
};

export default TripsView;
