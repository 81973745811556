import React from "react";
import { useAppSelector } from "../hooks/storeHooks";
import Sidebar, { drawerWidth } from "./Sidebar";
import { useTheme, useMediaQuery, Box } from "@mui/material";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const loggedIn = useAppSelector((s) => !!s.authReducer.token);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Box sx={{ height: "100vh", display: "flex", width: "100%" }}>
			{loggedIn && <Sidebar />}
			<Box
				sx={{
					flexGrow: 1,
					height: "100%",
					bgcolor: "archiGrey.main",
					overflow: "auto",
					ml: loggedIn && !isMobile ? `${drawerWidth}px` : 0,
					width: "100%",
				}}
			>
				{children}
			</Box>
		</Box>
	);
};

export default Layout;
