import { BrowserRouter, Route, Routes as AppRoutes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { initLogin } from "./services/authService";
import { useEffect, useState } from "react";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import Layout from "./components/Layout";
import { Routes } from "./config/Routes";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Views/Login/Login";
import "./App.css";
import DriversView from "./components/Views/DriversView/DriversView";
import VehiclesView from "./components/Views/VehiclesView/VehiclesView";
import SpotsView from "./components/Views/SpotsView/SpotsView";
import PassengersView from "./components/Views/PassengersView/PassengersView";
import CustomersView from "./components/Views/CustomersView/CustomerView";
import TripsView from "./components/Views/TripsView/TripsView";
import NewTrip from "./components/Views/TripsView/NewTrip";
import AddonsView from "./components/Views/VehiclesView/AddonsView";
import Home from "./components/Views/Home/Home";
import theme from "./theme/theme";

function validateSession() {
	const token = localStorage.getItem("token");
	if (token) {
		initLogin();
		return true;
	}
	return false;
}

function App() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const init = async () => {
			setLoading(true);
			initLogin().finally(() => setLoading(false));
		};
		init();
	}, []);

	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<Layout>
					{loading ? (
						<div className="h-full flex justify-center items-center">
							<div className="animate-spin text-altakBlue">
								<Icon size={6} path={mdiCircleSlice1}></Icon>
							</div>
						</div>
					) : (
						<AppRoutes>
							<Route
								path={Routes.Home}
								element={<ProtectedRoute element={<Home />} />}
							/>
							<Route
								path={Routes.NewTrip}
								element={<ProtectedRoute element={<NewTrip />} />}
							/>
							<Route
								path={Routes.Trips}
								element={<ProtectedRoute element={<TripsView />} />}
							/>
							<Route
								path={Routes.Drivers}
								element={<ProtectedRoute element={<DriversView />} />}
							/>
							<Route
								path={Routes.Vehicles}
								element={<ProtectedRoute element={<VehiclesView />} />}
							/>
							<Route
								path={Routes.Addons}
								element={<ProtectedRoute element={<AddonsView />} />}
							/>
							<Route
								path={Routes.Passengers}
								element={<ProtectedRoute element={<PassengersView />} />}
							/>
							<Route
								path={Routes.Customers}
								element={<ProtectedRoute element={<CustomersView />} />}
							/>
							<Route
								path={Routes.Spots}
								element={<ProtectedRoute element={<SpotsView />} />}
							/>
							<Route path={Routes.Login} element={<Login />} />
						</AppRoutes>
					)}
				</Layout>
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;
