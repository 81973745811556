import {
	Card,
	TextField,
	Button,
	Typography,
	Box,
	CircularProgress,
	CardContent,
	Paper,
	Fade,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Routes } from "../../../config/Routes";
import { useAppSelector } from "../../../hooks/storeHooks";
import { useForm } from "../../../hooks/useForm";
import { login } from "../../../services/authService";

const Login: React.FC = () => {
	const initialValues = {
		name: "",
		password: "",
	};
	const loggedIn = useAppSelector((s) => !!s.authReducer.token);
	const [values, , handleChange] = useForm(initialValues);
	const [loading, setloading] = useState(false);
	const [showForm, setShowForm] = useState(false);

	useEffect(() => {
		// Pequeña animación de entrada
		const timer = setTimeout(() => {
			setShowForm(true);
		}, 300);
		return () => clearTimeout(timer);
	}, []);

	if (loggedIn) {
		return <Navigate to={Routes.Home} />;
	}

	return (
		<Box
			sx={{
				height: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				background:
					"linear-gradient(135deg, #E5E5E5 0%, #f5f5f5 50%, #e0e0e0 100%)",
				position: "relative",
				overflow: "hidden",
				"&::before": {
					content: '""',
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					background:
						"radial-gradient(circle at 20% 30%, rgba(0, 55, 85, 0.03) 0%, transparent 60%), radial-gradient(circle at 80% 70%, rgba(255, 140, 0, 0.03) 0%, transparent 60%)",
					animation: "subtle-movement 15s ease-in-out infinite alternate",
				},
				"@keyframes subtle-movement": {
					"0%": { backgroundPosition: "0% 0%" },
					"100%": { backgroundPosition: "100% 100%" },
				},
			}}
		>
			<Fade in={showForm} timeout={1000}>
				<Card
					elevation={4}
					sx={{
						maxWidth: 400,
						width: "90%",
						borderRadius: 3,
						overflow: "visible",
					}}
				>
					<Box
						sx={{
							background: "linear-gradient(45deg, #003755 30%, #004d77 90%)",
							py: 3,
							borderTopLeftRadius: 12,
							borderTopRightRadius: 12,
							display: "flex",
							justifyContent: "center",
							position: "relative",
						}}
					>
						<Box
							sx={{
								position: "relative",
								width: 180,
								height: 180,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "#fff",
								borderRadius: "50%",
								p: 2,
								boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
							}}
						>
							<object
								data="6.svg"
								width="140"
								height="140"
								type="image/svg+xml"
								aria-label="Logo"
							>
								{" "}
							</object>
						</Box>
					</Box>

					<CardContent sx={{ px: 3, pt: 4, pb: 4 }}>
						<Typography
							variant="h5"
							component="h1"
							sx={{
								textAlign: "center",
								fontWeight: 600,
								color: "primary.main",
								mb: 3,
							}}
						>
							Bienvenido al sistema
						</Typography>

						<form
							onSubmit={(e) => {
								e.preventDefault();
								setloading(true);
								login(values.name, values.password).finally(() =>
									setloading(false)
								);
							}}
						>
							<TextField
								onChange={handleChange}
								id="name"
								name="name"
								label="Usuario"
								variant="outlined"
								fullWidth
								margin="normal"
								autoFocus
								disabled={loading}
								sx={{ mb: 2 }}
							/>
							<TextField
								onChange={handleChange}
								id="password"
								name="password"
								label="Contraseña"
								type="password"
								variant="outlined"
								fullWidth
								margin="normal"
								disabled={loading}
								sx={{ mb: 3 }}
							/>
							<Button
								type="submit"
								variant="contained"
								fullWidth
								disabled={loading}
								sx={{
									py: 1.5,
									fontSize: "1rem",
									backgroundColor: "secondary.main",
									"&:hover": {
										backgroundColor: "#d97600",
									},
								}}
							>
								{loading ? (
									<CircularProgress
										size={24}
										color="inherit"
										sx={{ color: "#fff" }}
									/>
								) : (
									"Iniciar sesión"
								)}
							</Button>
						</form>
					</CardContent>

					<Paper
						elevation={0}
						sx={{
							borderBottomLeftRadius: 12,
							borderBottomRightRadius: 12,
							p: 2,
							backgroundColor: "rgba(0, 55, 85, 0.05)",
							textAlign: "center",
						}}
					>
						<Typography variant="body2" color="text.secondary">
							Movirent
						</Typography>
					</Paper>
				</Card>
			</Fade>
		</Box>
	);
};

export default Login;
