import React, { useEffect, useState } from "react";
import {
	DataGrid,
	GridRenderCellParams,
	GridColDef,
	esES,
} from "@mui/x-data-grid";
import { Button, Card, Box, Typography, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppSelector } from "../../../hooks/storeHooks";
import { fetchDrivers, deleteDriver } from "../../../services/driverService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import NewDriverModal from "./NewDriverModal";

const DriversView: React.FC = () => {
	const theme = useTheme();
	const { isLoading, drivers } = useAppSelector(
		(state: any) => state.driverReducer
	);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedDriver, setSelectedDriver] = useState<Driver | null>(null);

	useEffect(() => {
		fetchDrivers();
	}, []);

	const columns: GridColDef[] = [
		{
			field: "name",
			minWidth: 150,
			flex: 1,
			headerName: "Nombre Conductor",
			headerAlign: "center",
			align: "center",
			renderCell: (params: GridRenderCellParams) => (
				<Typography variant="body1">{params.row.name}</Typography>
			),
		},
		{
			field: "rut",
			minWidth: 150,
			flex: 1,
			headerName: "Rut Conductor",
			headerAlign: "center",
			align: "center",
		},
		{
			field: "edit",
			minWidth: 100,
			flex: 1,
			headerName: "Editar Conductor",
			headerAlign: "center",
			align: "center",
			filterable: false,
			disableColumnMenu: true,
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<Button
					variant="contained"
					startIcon={<EditIcon />}
					sx={{
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.common.white,
						"&:hover": { backgroundColor: theme.palette.primary.dark },
					}}
					onClick={() => {
						const driver = drivers.find(
							(drv: Driver) => drv._id === params.row._id
						);
						if (driver) {
							setSelectedDriver(driver);
							setModalOpen(true);
						}
					}}
				>
					Editar
				</Button>
			),
		},
		{
			field: "delete",
			minWidth: 100,
			flex: 1,
			headerName: "Borrar Conductor",
			headerAlign: "center",
			align: "center",
			filterable: false,
			disableColumnMenu: true,
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<Button
					variant="contained"
					startIcon={<DeleteIcon />}
					sx={{
						backgroundColor: theme.palette.error.main,
						color: theme.palette.common.white,
						"&:hover": { backgroundColor: theme.palette.error.dark },
					}}
					onClick={() => deleteDriver(params.row._id)}
				>
					Borrar
				</Button>
			),
		},
	];

	return (
		<Box sx={{ p: 3, height: "100%", overflow: "auto" }}>
			<Typography variant="h4" sx={{ mb: 3 }}>
				Conductores
			</Typography>
			<Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
				<Button variant="contained" onClick={() => setModalOpen(true)}>
					Agregar
				</Button>
			</Box>
			{isLoading ? (
				<Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
					<Icon spin path={mdiCircleSlice1} className="w-7" />
				</Box>
			) : (
				<Card>
					<DataGrid
						sx={{ p: 2 }}
						autoHeight
						columns={columns}
						rows={drivers}
						pageSize={20}
						getRowId={(row) => row._id}
						localeText={esES.components.MuiDataGrid.defaultProps.localeText}
					/>
					<NewDriverModal
						open={modalOpen}
						driver={selectedDriver}
						setDriver={setSelectedDriver}
						onClose={() => setModalOpen(false)}
					/>
				</Card>
			)}
		</Box>
	);
};

export default DriversView;
