import axios from "./client";
import { TripActions } from "../reducers/TripReducer";
import store from "./../store";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchTrips = async () => {
	try {
		store.dispatch({ type: TripActions.RequestTrips, payload: {} });
		const { data: trips } = await axios.get<Trip[]>(`${API_URL}trips`);
		store.dispatch({
			type: TripActions.ReceiveTrips,
			payload: trips,
		});
	} catch (error) {
		console.error("Error obteniendo trips:", error);
		store.dispatch({
			type: TripActions.ReceiveTrips,
			payload: [],
		});
	}
};

export const addTrip = async (trip: Partial<Trip>) => {
	try {
		const { data: newTrip } = await axios.post<Trip>(`${API_URL}trips`, trip);
		store.dispatch({
			type: TripActions.AddTrip,
			payload: newTrip,
		});
		return newTrip;
	} catch (error) {
		console.error("Error al agregar viaje:", error);
		throw error;
	}
};

export const updateTrip = async (id: string, trip: Partial<Trip>) => {
	try {
		const { data: editedTrip } = await axios.patch<Trip>(
			`${API_URL}trips/${id}`,
			trip
		);
		store.dispatch({
			type: TripActions.AddTrip,
			payload: editedTrip,
		});
		return editedTrip;
	} catch (error) {
		console.error("Error al actualizar viaje:", error);
		throw error;
	}
};

export const generateTripPDF = (id: string, tripNumber: string | undefined) => {
	try {
		axios
			.get(API_URL + `trips/pdf/${id}`, {
				responseType: "blob",
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				const name = tripNumber ? tripNumber : id;
				link.setAttribute("download", `${name}.pdf`);
				document.body.appendChild(link);
				link.click();
				link.remove();
			})
			.catch((error) => {
				console.error("Error al descargar PDF:", error);
			});
	} catch (error) {
		console.error("Error al generar trip PDF:", error);
	}
};
