import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@mui/material";
import { useForm } from "../../../hooks/useForm";
import { addCustomer, updateCustomer } from "../../../services/customerService";
import { validateRUT } from "validar-rut";
import { useEffect } from "react";

interface Props {
	open: boolean;
	onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
	customer: Customer | null;
	setCustomer: (customer: Customer | null) => void;
}

const NewCustomerModal: React.FC<Props> = ({
	open,
	onClose,
	customer,
	setCustomer,
}) => {
	const initialValues = {
		name: "",
		rut: "",
		branch: "",
	};
	const [values, setValues, handleChange] = useForm(initialValues);
	useEffect(() => {
		if (customer) {
			setValues({
				...values,
				name: customer.name,
				rut: customer.rut,
				branch: customer.branch,
			});
		}
	}, [customer, open]);
	return (
		<Dialog
			open={open}
			onClose={onClose}
			onTransitionEnd={() => !open && setValues(initialValues)}
		>
			<DialogTitle>Nuevo Cliente</DialogTitle>
			<DialogContent>
				<div className="flex flex-col gap-2 mt-5">
					<TextField
						onChange={handleChange}
						id="rut"
						label="Rut"
						value={values.rut}
					></TextField>
					<TextField
						onChange={handleChange}
						id="name"
						label="Nombre"
						value={values.name}
					></TextField>
					<TextField
						onChange={handleChange}
						id="branch"
						label="Sucursal"
						value={values.branch}
					></TextField>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						if (customer !== null) {
							setCustomer(null);
							setValues(initialValues);
						}
						onClose();
					}}
				>
					Cancelar
				</Button>
				<Button
					onClick={() => {
						const rutValido = validateRUT(values.rut);

						if (customer !== null) {
							setCustomer(null);
							setValues(initialValues);
							updateCustomer(customer._id, values);
						} else if (rutValido) {
							addCustomer(values);
							setCustomer(null);
							setValues(initialValues);
						}
						onClose();
					}}
				>
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default NewCustomerModal;
