interface State {
	addons: Addon[];
	isLoading: boolean;
}
const initialState: State = {
	addons: [],
	isLoading: false,
};
export enum AddonActions {
	RequestAddons = "REQUEST_ADDONS",
	ReceiveAddons = "RECEIVE_ADDONS",
	AddAddon = "ADD_ADDON",
	DeleteAddon = "DELETE_ADDON",
	UpdateAddon = "UPDATE_ADDON",
}
const reducer = (
	state: State = initialState,
	{ type, payload }: { type: AddonActions; payload: any }
): State => {
	switch (type) {
		case AddonActions.RequestAddons:
			return { ...state, isLoading: true };
		case AddonActions.ReceiveAddons:
			return { ...state, addons: payload, isLoading: false };
		case AddonActions.AddAddon:
			if (payload && payload._id) {
				return {
					...state,
					addons: [...state.addons, payload],
				};
			}
			return state;
		case AddonActions.UpdateAddon:
			if (payload && payload._id) {
				return {
					...state,
					addons: state.addons.map((addon) =>
						addon._id === payload._id ? payload : addon
					),
				};
			}
			return state;
		case AddonActions.DeleteAddon:
			if (payload) {
				return {
					...state,
					addons: state.addons.filter((addon) => addon._id !== payload),
				};
			}
			return state;
		default:
			return state;
	}
};

export default reducer;
