import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const handleGenerateExcel = async (filteredTrips) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Reporte de Trayectos');

    // Estilos
    const style = { alignment: { horizontal: 'left' } };
    const dateStyle = { numFmt: 'DD/MM/YYYY', alignment: { horizontal: 'left' } };
    const timeStyle = { numFmt: 'HH:MM', alignment: { horizontal: 'left' } };

    // Definición de columnas basadas en el DataGrid
    worksheet.columns = [
        { header: 'Nombre Conductor', key: 'driverName', width: 25, style: style },
        { header: 'Tipo de Turno', key: 'shiftType', width: 15, style: style },
        { header: 'Dirección', key: 'tripDirection', width: 20, style: style },
        { header: 'Origen', key: 'origin', width: 20, style: style },
        { header: 'Destino', key: 'destination', width: 20, style: style },
        { header: 'Tipo de Transporte', key: 'transportType', width: 20, style: style },
        { header: 'Tipo de Vehículo', key: 'vehicleMachineryType', width: 20, style: style },
        { header: 'Patente', key: 'vehiclePlate', width: 15, style: style },
        { header: 'Patente Semi Remolque', key: 'addonPlate', width: 20, style: style },
        { header: 'Tipo Semi Remolque', key: 'addonType', width: 20, style: style },
        { header: 'Documento de Transporte', key: 'transportDocument', width: 25, style: style },
        { header: 'Numero de Viaje', key: 'tripNumber', width: 15, style: style },
        { header: 'Cliente', key: 'customer', width: 30, style: style },
        { header: 'Fecha Programada', key: 'programmedDate', width: 20, style: dateStyle },
        { header: 'Hora Programada', key: 'programmedHour', width: 15, style: timeStyle },
        { header: 'Observaciones', key: 'observations', width: 30, style: style },
    ];

    // Agregar filas
    filteredTrips.forEach((trip) => {
        const programmedDate = trip.programmedAt ? new Date(trip.programmedAt) : null;
        
        worksheet.addRow({
            driverName: trip.driver ? trip.driver.name : 'Conductor Eliminado',
            shiftType: trip.shiftType || '-',
            tripDirection: trip.tripDirection || '-',
            origin: trip.origin || '',
            destination: trip.destination || '',
            transportType: trip.transportType || '-',
            vehicleMachineryType: trip.vehicleMachineryType || '-',
            vehiclePlate: trip.vehiclePlate || '',
            addonPlate: trip.addonPlate || '',
            addonType: trip.addonType || '',
            transportDocument: trip.transportDocument || '',
            tripNumber: trip.tripNumber || '',
            customer: trip.customer 
                ? (trip.customer.branch 
                    ? `${trip.customer.name} - ${trip.customer.branch}` 
                    : trip.customer.name)
                : 'Cliente eliminado',
            programmedDate: programmedDate 
                ? programmedDate.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }) 
                : '',
            programmedHour: programmedDate 
                ? `${programmedDate.getHours()}:${programmedDate.getMinutes().toString().padStart(2, '0')}` 
                : '',
            observations: trip.observations || '',
        });
    });

    // Aplicar estilos a los encabezados
    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).alignment = { horizontal: 'center' };

    // Generar el archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'reporte_trayectos.xlsx');
};

export default handleGenerateExcel;
