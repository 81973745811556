import { useAppSelector } from "../../../hooks/storeHooks";
import {
	Box,
	Card,
	CardContent,
	Typography,
	Avatar,
	Paper,
	Fade,
	Grow,
} from "@mui/material";
import { useState, useEffect } from "react";

const Home: React.FC = () => {
	const user = useAppSelector((s) => s.authReducer.user);
	const [showWelcome, setShowWelcome] = useState(false);
	const [currentDateTime, setCurrentDateTime] = useState(new Date());

	// Función para obtener el saludo según la hora
	const getGreeting = () => {
		const hour = new Date().getHours();
		if (hour < 12) return "¡Buenos días";
		if (hour < 19) return "¡Buenas tardes";
		return "¡Buenas noches";
	};

	useEffect(() => {
		// Pequeña animación de entrada
		const timer = setTimeout(() => {
			setShowWelcome(true);
		}, 300);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		// Actualiza la hora cada minuto
		const interval = setInterval(() => {
			setCurrentDateTime(new Date());
		}, 60000);

		return () => clearInterval(interval);
	}, []);

	return (
		<Box
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				background:
					"linear-gradient(135deg, #E5E5E5 0%, #f5f5f5 50%, #e0e0e0 100%)",
				p: 3,
				position: "relative",
				overflow: "hidden",
				"&::before": {
					content: '""',
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					background:
						"radial-gradient(circle at 20% 30%, rgba(0, 55, 85, 0.03) 0%, transparent 60%), radial-gradient(circle at 80% 70%, rgba(255, 140, 0, 0.03) 0%, transparent 60%)",
					animation: "subtle-movement 15s ease-in-out infinite alternate",
				},
				"@keyframes subtle-movement": {
					"0%": { backgroundPosition: "0% 0%" },
					"100%": { backgroundPosition: "100% 100%" },
				},
			}}
		>
			<Fade in={showWelcome} timeout={1000}>
				<Card
					sx={{
						maxWidth: 500,
						width: "100%",
						textAlign: "center",
						overflow: "visible",
						position: "relative",
						mt: 5,
					}}
				>
					<Box
						sx={{
							position: "absolute",
							top: -60,
							left: "50%",
							transform: "translateX(-50%)",
						}}
					>
						<Avatar
							src={"bienvenido.png"}
							alt="Bienvenido"
							sx={{
								width: 120,
								height: 120,
								border: "5px solid white",
								boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
							}}
						/>
					</Box>

					<CardContent sx={{ mt: 8, pb: 3 }}>
						<Typography
							variant="h4"
							component="h1"
							sx={{
								fontWeight: 600,
								color: "primary.main",
								mb: 1,
							}}
						>
							{getGreeting()}, {user?.name?.split(" ")[0]}!
						</Typography>

						<Typography
							variant="body1"
							sx={{
								color: "text.secondary",
								mt: 2,
								fontWeight: 500,
								fontSize: "0.9rem",
								display: "flex",
								justifyContent: "center",
								gap: 1,
							}}
						>
							<Box
								component="span"
								sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
							>
								📅{" "}
								{currentDateTime.toLocaleDateString("es-ES", {
									weekday: "long",
									year: "numeric",
									month: "long",
									day: "numeric",
								})}
							</Box>
							|
							<Box
								component="span"
								sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
							>
								⏰{" "}
								{currentDateTime.toLocaleTimeString("es-ES", {
									hour: "2-digit",
									minute: "2-digit",
								})}
							</Box>
						</Typography>

						<Grow
							in={showWelcome}
							timeout={1500}
							style={{ transformOrigin: "0 0 0" }}
						>
							<Paper
								elevation={0}
								sx={{
									backgroundColor: "rgba(0, 55, 85, 0.05)",
									p: 2,
									borderRadius: 3,
									mt: 3,
								}}
							>
								<Typography
									variant="h5"
									sx={{
										fontWeight: 500,
										color: "secondary.main",
									}}
								>
									{user?.name}
								</Typography>
							</Paper>
						</Grow>

						{/* Accesos rápidos */}
						<Grow
							in={showWelcome}
							timeout={1800}
							style={{ transformOrigin: "0 0 0" }}
						>
							<Box sx={{ mt: 4 }}>
								<Typography
									variant="h6"
									sx={{
										mb: 2,
										color: "primary.main",
										fontWeight: 500,
									}}
								>
									Accesos rápidos
								</Typography>
								<Box
									sx={{
										display: "flex",
										flexWrap: "wrap",
										gap: 2,
										justifyContent: "center",
									}}
								>
									<Paper
										elevation={0}
										sx={{
											backgroundColor: "primary.main",
											borderRadius: 2,
											width: 120,
											height: 90,
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
											transition: "transform 0.3s, box-shadow 0.3s",
											"&:hover": {
												transform: "translateY(-5px)",
												boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.15)",
											},
										}}
										onClick={() => (window.location.href = "/trips")}
									>
										<Box sx={{ fontSize: "2rem" }}>🚚</Box>
										<Typography
											sx={{ color: "white", fontSize: "0.9rem", mt: 1 }}
										>
											Trayectos
										</Typography>
									</Paper>

									{user?.role === "manager" && (
										<Paper
											elevation={0}
											sx={{
												backgroundColor: "secondary.main",
												borderRadius: 2,
												width: 120,
												height: 90,
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												justifyContent: "center",
												cursor: "pointer",
												transition: "transform 0.3s, box-shadow 0.3s",
												"&:hover": {
													transform: "translateY(-5px)",
													boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.15)",
												},
											}}
											onClick={() => (window.location.href = "/drivers")}
										>
											<Box sx={{ fontSize: "2rem" }}>👨‍✈️</Box>
											<Typography
												sx={{ color: "white", fontSize: "0.9rem", mt: 1 }}
											>
												Conductores
											</Typography>
										</Paper>
									)}

									{user?.role === "manager" && (
										<Paper
											elevation={0}
											sx={{
												backgroundColor: "#4CAF50",
												borderRadius: 2,
												width: 120,
												height: 90,
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												justifyContent: "center",
												cursor: "pointer",
												transition: "transform 0.3s, box-shadow 0.3s",
												"&:hover": {
													transform: "translateY(-5px)",
													boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.15)",
												},
											}}
											onClick={() => (window.location.href = "/vehicles")}
										>
											<Box sx={{ fontSize: "2rem" }}>🚗</Box>
											<Typography
												sx={{ color: "white", fontSize: "0.9rem", mt: 1 }}
											>
												Vehículos
											</Typography>
										</Paper>
									)}
								</Box>
							</Box>
						</Grow>

						<Typography
							variant="body2"
							color="text.secondary"
							sx={{ mt: 4, fontStyle: "italic", mb: 3 }}
						>
							Selecciona una opción del menú para comenzar
						</Typography>
					</CardContent>
				</Card>
			</Fade>
		</Box>
	);
};

export default Home;
