import axios from "./client";
import { AddonActions } from "../reducers/AddonReducer";
import store from "./../store";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAddons = async () => {
	try {
		store.dispatch({ type: AddonActions.RequestAddons, payload: {} });
		const { data: addons } = await axios.get<Addon[]>(`${API_URL}addons`);
		store.dispatch({
			type: AddonActions.ReceiveAddons,
			payload: addons,
		});
	} catch (error) {
		console.error("Error al obtener remolques:", error);
		store.dispatch({
			type: AddonActions.ReceiveAddons,
			payload: [],
		});
	}
};

export const addAddon = async (addon: Partial<Addon>) => {
	try {
		const { data: newAddon } = await axios.post<Addon>(
			`${API_URL}addons`,
			addon
		);
		store.dispatch({
			type: AddonActions.AddAddon,
			payload: newAddon,
		});
		return newAddon;
	} catch (error) {
		console.error("Error al crear remolque:", error);
		throw error;
	}
};

export const deleteAddon = async (id: string) => {
	try {
		const { data: addon } = await axios.delete<Addon>(`${API_URL}addons/${id}`);
		store.dispatch({
			type: AddonActions.DeleteAddon,
			payload: id,
		});
		return addon;
	} catch (error) {
		console.error("Error al eliminar remolque:", error);
		throw error;
	}
};

export const updateAddon = async (id: string, addon: Partial<Addon>) => {
	try {
		const { data: editedAddon } = await axios.patch<Addon>(
			`${API_URL}addons/${id}`,
			addon
		);
		store.dispatch({
			type: AddonActions.UpdateAddon, // Usé UpdateAddon en vez de AddAddon
			payload: editedAddon,
		});
		return editedAddon;
	} catch (error) {
		console.error("Error al actualizar remolque:", error);
		throw error;
	}
};
