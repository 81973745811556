import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@mui/material";
import { useEffect } from "react";
import { useForm } from "../../../hooks/useForm";
import { addAddon, updateAddon } from "../../../services/addonService";

interface Props {
	open: boolean;
	onClose: (success?: boolean) => void;
	addon: Addon | null;
	setAddon: (addon: Addon | null) => void;
}

const initialValues = {
	plate: "",
	addonType: "",
};

const NewAddonModal: React.FC<Props> = ({ open, onClose, addon, setAddon }) => {
	const [values, setValues, handleChange] = useForm(initialValues);

	useEffect(() => {
		if (addon !== null) {
			setValues((prevValues) => ({
				...prevValues,
				plate: addon.plate,
				addonType: addon.addonType || "",
			}));
		}
	}, [addon]);

	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			onTransitionEnd={() => !open && setValues(initialValues)}
		>
			<DialogTitle>{addon ? "Editar" : "Nuevo"} Semi Remolque</DialogTitle>
			<DialogContent>
				<div className="flex flex-col gap-2 mt-5">
					<TextField
						onChange={handleChange}
						id="plate"
						label="Placa"
						value={values.plate}
						fullWidth
						margin="normal"
					/>

					<FormControl fullWidth margin="normal">
						<InputLabel id="addonType-label">Tipo de Remolque</InputLabel>
						<Select
							labelId="addonType-label"
							id="addonType"
							value={values.addonType}
							label="Tipo de Remolque"
							onChange={(e) =>
								setValues((prev) => ({ ...prev, addonType: e.target.value }))
							}
						>
							<MenuItem value="Cama Baja">Cama Baja</MenuItem>
							<MenuItem value="Rampa">Rampa</MenuItem>
						</Select>
					</FormControl>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						if (addon !== null) {
							setAddon(null);
							setValues(initialValues);
						}
						onClose(false);
					}}
				>
					Cancelar
				</Button>
				<Button
					variant="contained"
					disabled={values.plate === "" || values.addonType === ""} // Validar addonType
					onClick={async () => {
						try {
							if (addon !== null) {
								await updateAddon(addon._id, values);
								setAddon(null);
							} else {
								await addAddon(values);
							}
							setValues(initialValues);
							onClose(true);
						} catch (error) {
							console.error("Error al guardar remolque:", error);
							onClose(false);
						}
					}}
				>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default NewAddonModal;
