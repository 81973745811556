import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@mui/material";
import { useForm } from "../../../hooks/useForm";
import { addSpot, updateSpot } from "../../../services/spotService";

import { useEffect } from "react";

interface Props {
	open: boolean;
	onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
	spot: Spot | null;
	setSpot: (spot: Spot | null) => void;
}

const NewSpotModal: React.FC<Props> = ({ open, onClose, spot, setSpot }) => {
	const initialValues = {
		name: "",
	};

	const [values, setValues, handleChange] = useForm(initialValues);
	useEffect(() => {
		if (spot) {
			setValues({ ...values, name: spot.name });
		}
	}, [spot, open]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			onTransitionEnd={() => !open && setValues(initialValues)}
		>
			<DialogTitle>Nuevo Spot</DialogTitle>
			<DialogContent>
				<div className="flex flex-col gap-2 mt-5">
					<TextField
						onChange={handleChange}
						id="name"
						label="Nombre"
						value={values.name}
					></TextField>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						if (spot !== null) {
							setSpot(null);
							setValues(initialValues);
						}
						onClose();
					}}
				>
					Cancelar
				</Button>
				<Button
					variant={"contained"}
					disabled={values.name === ""}
					onClick={() => {
						if (spot) {
							updateSpot(spot._id, values)
								.then(() => {
									onClose();
									setSpot(null);
								})
								.catch(() => {
									alert("Error al actualizar el spot");
								});
						} else {
							addSpot(values)
								.then(() => {
									onClose();
									setSpot(null);
								})
								.catch(() => {
									alert("Error al crear el spot");
								});
						}
					}}
				>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default NewSpotModal;
