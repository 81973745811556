import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { useForm } from "../../../hooks/useForm";
import { addVehicle, updateVehicle } from "../../../services/vehicleService";

interface Props {
	open: boolean;
	onClose: (success?: boolean) => void;
	vehicle: Vehicle | null;
	setVehicle: (vehicle: Vehicle | null) => void;
}

const initialValues = {
	plate: "",
	typeOfService: "",
	machineryType: "",
};

const NewVehicleModal: React.FC<Props> = ({
	open,
	onClose,
	vehicle,
	setVehicle,
}) => {
	const user = useAppSelector((s) => s.authReducer.user);
	const [values, setValues, handleChange] = useForm(initialValues);
	const drivers = useAppSelector((s) => s.driverReducer.drivers);
	const [selectedDriver, setSelectedDriver] = useState<string>("");
	const [multipleServices, setMultipleServices] = useState(true);
	const [machineTypeOptions, setMachineTypeOptions] = useState<string[]>([]);

	useEffect(() => {
		if (user!.enterprise.services.length === 1) {
			const serviceType = user!.enterprise.services[0];
			setValues((prevValues) => ({
				...prevValues,
				typeOfService: serviceType,
			}));
			updateMachineTypeOptions(serviceType);
			setMultipleServices(false);
		}
	}, [vehicle, open, user, setValues]);

	useEffect(() => {
		if (vehicle !== null) {
			setValues((prevValues) => ({
				...prevValues,
				plate: vehicle.plate,
				typeOfService: vehicle.typeOfService,
				machineryType: vehicle.machineryType || "",
			}));
			updateMachineTypeOptions(vehicle.typeOfService);

			// Comprobar que el driver existe y no es null
			if (vehicle.driver) {
				drivers.forEach((driver) => {
					if (driver.rut === vehicle.driver.rut) {
						setSelectedDriver(driver._id);
					}
				});
			} else {
				// Si no hay conductor, poner "Sin Conductor" como valor
				setSelectedDriver("Sin Conductor");
			}
		}
	}, [vehicle, drivers, setValues]);

	const updateMachineTypeOptions = (serviceType: string) => {
		const normalizedType = serviceType.toLowerCase();

		let options: string[] = [];
		if (normalizedType === "pasajeros") {
			options = ["Bus normal", "Mini bus"];
		} else if (normalizedType === "carga") {
			options = [
				"Camión 3/4 3000 kgs",
				"Camión 3/4 10000 kgs",
				"Tracto camión",
			];
		}

		setMachineTypeOptions(options);
	};
	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)} // Avisa que se cerró sin éxito
			onTransitionEnd={() => !open && setValues(initialValues)}
		>
			<DialogTitle>{vehicle ? "Editar" : "Nuevo"} Vehiculo</DialogTitle>{" "}
			{/* Título dinámico */}
			<DialogContent>
				<div className="flex flex-col gap-2 mt-5">
					<TextField
						onChange={handleChange}
						id="plate"
						value={values.plate}
						label="Patente"
					></TextField>
					{multipleServices && (
						<FormControl fullWidth>
							<InputLabel id="typeOfService-label">Tipo de Servicio</InputLabel>
							<Select
								labelId="typeOfService-label"
								id="typeOfService"
								value={values.typeOfService}
								label="Unidad"
								onChange={(e) => {
									const serviceType = e.target.value;
									setValues({
										...values,
										typeOfService: serviceType,
										machineryType: "",
									});
									updateMachineTypeOptions(serviceType);
								}}
							>
								{user!.enterprise.services.map((service, index) => (
									<MenuItem key={index} value={service}>
										{service}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{machineTypeOptions.length > 0 && (
						<FormControl fullWidth>
							<InputLabel id="machineType-label">Tipo de Vehiculo</InputLabel>
							<Select
								labelId="machineryType-label"
								id="machineryType"
								value={values.machineryType}
								label="Tipo de Vehiculo"
								onChange={(e) =>
									setValues({ ...values, machineryType: e.target.value })
								}
							>
								{machineTypeOptions.map((option, index) => (
									<MenuItem key={index} value={option}>
										{option}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}

					<FormControl fullWidth>
						<InputLabel id="driver-label">Conductor</InputLabel>
						<Select
							labelId="driver-label"
							id="driver"
							value={selectedDriver}
							label="Conductor"
							onChange={(e) => {
								setSelectedDriver(e.target.value as string);
							}}
						>
							<MenuItem value={"Sin Conductor"}>Sin Conductor </MenuItem>
							{drivers.map((driver) => (
								<MenuItem key={driver._id} value={driver._id}>
									{driver.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						if (vehicle !== null) {
							setVehicle(null);
							setValues(initialValues);
						}
						onClose(false); // Avisa que se canceló sin éxito
					}}
				>
					Cancelar
				</Button>

				<Button
					variant="contained"
					disabled={
						values.plate === "" ||
						values.typeOfService === "" ||
						(machineTypeOptions.length > 0 && values.machineryType === "")
					}
					onClick={async () => {
						try {
							// Manejo de errores
							var driverToSend = undefined;
							if (selectedDriver !== "Sin Conductor") {
								driverToSend = drivers.find(
									(driver) => driver._id === selectedDriver
								);
							}

							if (vehicle !== null) {
								// Actualizar vehículo
								await updateVehicle(vehicle._id, {
									...values,
									driver: driverToSend,
								});
								setVehicle(null);
							} else {
								await addVehicle({ ...values, driver: driverToSend });
							}

							// Avisa del éxito al cerrar
							onClose(true);
						} catch (error) {
							console.error("Error al guardar vehículo:", error);
							onClose(false);
						}
					}}
				>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default NewVehicleModal;
