export const filterTrips = (
	trips: Trip[],
	options: {
		fromDate?: Date | null;
		toDate?: Date | null;
		customerNameFilter?: string;
		transportTypeFilter?: string;
		applyDateFilter?: boolean;
	} = {}
): Trip[] => {
	const {
		fromDate = null,
		toDate = null,
		customerNameFilter = "",
		transportTypeFilter = "todos",
		applyDateFilter = false,
	} = options;

	if (!trips || trips.length === 0) return [];

	let filteredTrips = [...trips];

	if (customerNameFilter && customerNameFilter.trim() !== "") {
		const searchTerm = customerNameFilter.toLowerCase().trim();
		filteredTrips = filteredTrips.filter((trip) => {
			if (!trip.customer) return false;

			const customerName = trip.customer.name.toLowerCase();
			const branch = trip.customer.branch
				? trip.customer.branch.toLowerCase()
				: "";

			return customerName.includes(searchTerm) || branch.includes(searchTerm);
		});
	}

	if (transportTypeFilter && transportTypeFilter !== "todos") {
		filteredTrips = filteredTrips.filter((trip) => {
			if (!trip.transportType) return false;

			const transportType = trip.transportType.toLowerCase();

			return transportTypeFilter === "pasajeros"
				? transportType.includes("pasajero")
				: transportType.includes("carga");
		});
	}

	if (applyDateFilter && fromDate && toDate) {
		const from = new Date(fromDate);
		const to = new Date(toDate);

		from.setHours(0, 0, 0, 0);
		to.setHours(23, 59, 59, 999);

		const fromTime = from.getTime();
		const toTime = to.getTime();

		filteredTrips = filteredTrips.filter((trip) => {
			if (!trip.programmedAt) return false;
			const tripTime = new Date(trip.programmedAt).getTime();
			return fromTime <= tripTime && tripTime <= toTime;
		});
	}

	return filteredTrips;
};

export const filterByCustomerName = (
	trips: Trip[],
	customerNameFilter: string
): Trip[] => {
	return filterTrips(trips, { customerNameFilter });
};

export const filterByTransportType = (
	trips: Trip[],
	transportTypeFilter: string
): Trip[] => {
	return filterTrips(trips, { transportTypeFilter });
};

export const filterByDateRange = (
	trips: Trip[],
	fromDate: Date,
	toDate: Date
): Trip[] => {
	return filterTrips(trips, { fromDate, toDate, applyDateFilter: true });
};
