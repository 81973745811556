import {
	DataGrid,
	GridRenderCellParams,
	GridColDef,
	GridToolbarContainer,
} from "@mui/x-data-grid";
import { Button, Card } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import {
	fetchPassengers,
	deletePassenger,
} from "../../../services/passengerService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import NewPassengersModal from "./NewPassengersModal";

const CustomToolbar = () => {
	return <GridToolbarContainer></GridToolbarContainer>;
};

const PassengersView: React.FC = () => {
	const { isLoading, passengers } = useAppSelector(
		(state: any) => state.passengerReducer
	);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedPassenger, setSelectedPassenger] = useState<Passenger | null>(
		null
	);

	const columns: GridColDef[] = [
		{ field: "name", minWidth: 150, flex: 1, headerName: "Nombre" },
		{ field: "rut", minWidth: 150, flex: 1, headerName: "Rut" },
		{
			field: "edit",
			minWidth: 100,
			flex: 1,
			headerName: "Editar",
			renderCell: (params: GridRenderCellParams) => (
				<Button
					sx={{ backgroundColor: "blue", color: "white" }}
					onClick={() => {
						for (let i = 0; i < passengers.length; i++) {
							if (passengers[i]._id === params.row._id) {
								setSelectedPassenger(passengers[i]);
								setModalOpen(true);
							}
						}
					}}
				>
					{" "}
					Editar{" "}
				</Button>
			),
		},
		{
			field: "delete",
			minWidth: 100,
			flex: 1,
			headerName: "Eliminar",
			renderCell: (params: GridRenderCellParams) => (
				<Button
					sx={{ backgroundColor: "red", color: "white" }}
					onClick={() => {
						deletePassenger(params.row._id);
					}}
				>
					{" "}
					Eliminar{" "}
				</Button>
			),
		},
	];
	useEffect(() => {
		fetchPassengers();
	}, []);
	return (
		<div className="p-5 h-full overflow-auto">
			<div className="text-xl mb-5">Pasajeros</div>
			<div className="mb-3 flex justify-end pr-7">
				<Button variant="contained" onClick={() => setModalOpen(true)}>
					{" "}
					Agregar
				</Button>
			</div>
			<div>
				{isLoading ? (
					<div className="flex justify-center w-full col-span-full">
						<Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
					</div>
				) : (
					<Card>
						<DataGrid
							sx={{ padding: 2 }}
							autoHeight={true}
							columns={columns}
							rows={passengers}
							getRowId={(row) => row._id}
							pageSize={20}
							components={{ Toolbar: CustomToolbar }}
						/>
						<NewPassengersModal
							open={modalOpen}
							onClose={() => setModalOpen(false)}
							passenger={selectedPassenger}
							setPassenger={setSelectedPassenger}
						/>
					</Card>
				)}
			</div>
		</div>
	);
};
export default PassengersView;
