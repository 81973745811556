import {
	DataGrid,
	GridRenderCellParams,
	GridColDef,
	esES,
} from "@mui/x-data-grid";
import { Button, Card, Box, Typography, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { fetchVehicles, deleteVehicle } from "../../../services/vehicleService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import NewVehicleModal from "./NewVehicleModal";
import { fetchDrivers } from "../../../services/driverService";

const VehiclesView: React.FC = () => {
	const theme = useTheme();
	const { isLoading, vehicles } = useAppSelector(
		(state) => state.vehicleReducer
	);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);

	const columns: GridColDef[] = [
		{
			field: "plate",
			minWidth: 150,
			flex: 1,
			headerName: "Patente",
			headerAlign: "center",
			align: "center",
		},

		{
			field: "name",
			minWidth: 150,
			flex: 1,
			headerName: "Nombre Conductor",
			headerAlign: "center",
			align: "center",
			renderCell: (params: GridRenderCellParams) => (
				<Typography variant="body1">
					{!!params.row.driver ? params.row.driver.name : "Sin Conductor"}
				</Typography>
			),
		},

		{
			field: "typeOfService",
			minWidth: 150,
			flex: 1,
			headerName: "Tipo de Servicio",
			headerAlign: "center",
			align: "center",
		},

		{
			field: "machineryType",
			minWidth: 150,
			flex: 1,
			headerName: "Tipo de Vehículo",
			headerAlign: "center",
			align: "center",
			renderCell: (params: GridRenderCellParams) => (
				<Typography variant="body1">
					{params.row.machineryType || "-"}
				</Typography>
			),
		},

		{
			field: "edit",
			minWidth: 100,
			flex: 1,
			headerName: "Editar Vehículo",
			headerAlign: "center",
			align: "center",
			filterable: false,
			disableColumnMenu: true,
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<Button
					variant="contained"
					startIcon={<EditIcon />}
					sx={{
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.common.white,
						"&:hover": { backgroundColor: theme.palette.primary.dark },
					}}
					onClick={() => {
						const vehicleToEdit = vehicles.find(
							(v) => v._id === params.row._id
						);
						if (vehicleToEdit) {
							setSelectedVehicle(vehicleToEdit);
							setModalOpen(true);
						}
					}}
				>
					Editar
				</Button>
			),
		},

		{
			field: "delete",
			minWidth: 100,
			flex: 1,
			headerName: "Borrar Vehículo",
			headerAlign: "center",
			align: "center",
			filterable: false,
			disableColumnMenu: true,
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<Button
					variant="contained"
					startIcon={<DeleteIcon />}
					sx={{
						backgroundColor: theme.palette.error.main,
						color: theme.palette.common.white,
						"&:hover": { backgroundColor: theme.palette.error.dark },
					}}
					onClick={() => deleteVehicle(params.row._id)}
				>
					Borrar
				</Button>
			),
		},
	];
	useEffect(() => {
		fetchVehicles();
		fetchDrivers();
	}, []);

	return (
		<Box sx={{ p: 3, height: "100%", overflow: "auto" }}>
			<Typography variant="h4" sx={{ mb: 3 }}>
				Vehículos
			</Typography>
			<Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
				<Button
					variant="contained"
					onClick={() => {
						setSelectedVehicle(null);
						setModalOpen(true);
					}}
				>
					Agregar
				</Button>
			</Box>

			{isLoading ? (
				<Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
					<Icon spin path={mdiCircleSlice1} className="w-7" />
				</Box>
			) : (
				<Card>
					<DataGrid
						sx={{ p: 2 }}
						autoHeight
						columns={columns}
						rows={vehicles}
						pageSize={20}
						getRowId={(row) => row._id}
						localeText={esES.components.MuiDataGrid.defaultProps.localeText}
					/>
					<NewVehicleModal
						open={modalOpen}
						onClose={(success) => {
							setModalOpen(false);
						}}
						vehicle={selectedVehicle}
						setVehicle={setSelectedVehicle}
					/>
				</Card>
			)}
		</Box>
	);
};

export default VehiclesView;
