import React, { useEffect, useState } from "react";
import {
	DataGrid,
	GridRenderCellParams,
	GridColDef,
	esES,
} from "@mui/x-data-grid";
import { Button, Card, Box, Typography, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppSelector } from "../../../hooks/storeHooks";
import {
	fetchCustomers,
	deleteCustomer,
} from "../../../services/customerService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import NewCustomerModal from "./NewCustomerModal";

const CustomersView: React.FC = () => {
	const theme = useTheme();
	const { isLoading, customers } = useAppSelector(
		(state: any) => state.customerReducer
	);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
		null
	);

	useEffect(() => {
		fetchCustomers();
	}, []);

	const columns: GridColDef[] = [
		{
			field: "name",
			minWidth: 150,
			flex: 1,
			headerName: "Nombre Cliente",
			headerAlign: "center",
			align: "center",

			renderCell: (params: GridRenderCellParams) => (
				<Typography variant="body1">
					{params.row.branch
						? `${params.row.name} - ${params.row.branch}`
						: params.row.name}
				</Typography>
			),
		},
		{ field: "rut", minWidth: 150, flex: 1, headerName: "Rut Cliente" },
		{
			field: "edit",
			minWidth: 100,
			flex: 1,
			headerName: "Editar Cliente",
			headerAlign: "center",
			align: "center",
			filterable: false,
			disableColumnMenu: true,
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<Button
					variant="contained"
					startIcon={<EditIcon />}
					sx={{
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.common.white,
						"&:hover": { backgroundColor: theme.palette.primary.dark },
					}}
					onClick={() => {
						const customer = customers.find(
							(cust: Customer) => cust._id === params.row._id
						);
						if (customer) {
							setSelectedCustomer(customer);
							setModalOpen(true);
						}
					}}
				>
					Editar
				</Button>
			),
		},
		{
			field: "delete",
			minWidth: 100,
			flex: 1,
			headerName: "Eliminar Cliente",
			headerAlign: "center",
			align: "center",
			filterable: false,
			disableColumnMenu: true,
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<Button
					variant="contained"
					color="error"
					startIcon={<DeleteIcon />}
					onClick={() => deleteCustomer(params.row._id)}
				>
					Eliminar
				</Button>
			),
		},
	];

	return (
		<Box sx={{ p: 3, height: "100%", overflow: "auto" }}>
			<Typography variant="h4" sx={{ mb: 3 }}>
				Clientes
			</Typography>
			<Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
				<Button variant="contained" onClick={() => setModalOpen(true)}>
					Agregar Cliente
				</Button>
			</Box>
			{isLoading ? (
				<Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
					<Icon spin path={mdiCircleSlice1} className="w-7" />
				</Box>
			) : (
				<Card>
					<DataGrid
						sx={{ p: 2 }}
						autoHeight
						columns={columns}
						rows={customers}
						pageSize={20}
						getRowId={(row) => row._id}
						localeText={esES.components.MuiDataGrid.defaultProps.localeText}
					/>
					<NewCustomerModal
						open={modalOpen}
						customer={selectedCustomer}
						setCustomer={setSelectedCustomer}
						onClose={() => setModalOpen(false)}
					/>
				</Card>
			)}
		</Box>
	);
};

export default CustomersView;
