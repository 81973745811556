import React, { useState } from "react";
import {
	Drawer,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	styled,
	Divider,
	IconButton,
	useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
	Link as RouterLink,
	LinkProps as RouterLinkProps,
	useLocation,
} from "react-router-dom";
import { useAppSelector } from "../hooks/storeHooks";
import { AuthActions } from "../reducers/AuthReducer";
import store from "../store";
import HomeIcon from "@mui/icons-material/Home";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AddIcon from "@mui/icons-material/Add";
import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { Routes } from "../config/Routes";

export const drawerWidth = 240;

const SidebarDrawer = styled(Drawer)(({ theme }) => ({
	"& .MuiDrawer-paper": {
		width: drawerWidth,
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		border: "none",
		boxShadow: "2px 0px 5px rgba(0,0,0,0.2)",
	},
}));

const CustomLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
	(props, ref) => <RouterLink ref={ref} {...props} />
);

interface SidebarItemProps {
	to: Routes;
	icon: React.ReactNode;
	label: string;
	selected: boolean;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
	to,
	icon,
	label,
	selected,
}) => {
	const theme = useTheme();
	return (
		<ListItemButton
			component={CustomLink}
			to={to}
			selected={selected}
			sx={{
				"&:hover": {
					backgroundColor: theme.palette.secondary.main,
					transition: "background-color 0.3s ease",
				},
				"&.Mui-selected": {
					backgroundColor: "rgba(255, 140, 0, 0.15)",
					"&:hover": {
						backgroundColor: theme.palette.secondary.main,
						"& .MuiListItemText-primary": {
							color: theme.palette.primary.main,
						},
						"& .MuiSvgIcon-root": {
							color: theme.palette.primary.main,
						},
					},
				},
			}}
		>
			<ListItemIcon>
				{React.cloneElement(icon as React.ReactElement, {
					sx: {
						color: selected ? theme.palette.secondary.main : "#fff",
					},
				})}
			</ListItemIcon>
			<ListItemText
				primary={label}
				sx={{
					"& .MuiListItemText-primary": {
						color: selected ? theme.palette.secondary.main : "#fff",
						fontWeight: selected ? 600 : 400,
					},
				}}
			/>
		</ListItemButton>
	);
};

const Sidebar: React.FC = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const location = useLocation();
	const user = useAppSelector((s) => s.authReducer.user);
	const [mobileOpen, setMobileOpen] = useState(false);

	const drawerContent = (
		<List>
			<SidebarItem
				to={Routes.Home}
				selected={location.pathname === Routes.Home}
				icon={<HomeIcon sx={{ color: "#fff" }} />}
				label="Inicio"
			/>

			{user?.enterprise.adminDecidesTrips && user?.role === "manager" && (
				<SidebarItem
					to={Routes.NewTrip}
					selected={location.pathname === Routes.NewTrip}
					icon={<AddIcon sx={{ color: "#fff" }} />}
					label="Nuevo Trayecto"
				/>
			)}

			<SidebarItem
				to={Routes.Trips}
				selected={location.pathname === Routes.Trips}
				icon={<LocalShippingIcon sx={{ color: "#fff" }} />}
				label="Trayectos"
			/>

			{user?.role === "manager" && (
				<SidebarItem
					to={Routes.Drivers}
					selected={location.pathname === Routes.Drivers}
					icon={<DriveEtaIcon sx={{ color: "#fff" }} />}
					label="Conductores"
				/>
			)}

			{user?.role === "manager" && (
				<SidebarItem
					to={Routes.Vehicles}
					selected={location.pathname === Routes.Vehicles}
					icon={<DirectionsCarIcon sx={{ color: "#fff" }} />}
					label="Vehículos"
				/>
			)}

			{user?.enterprise.services.includes("Carga") && (
				<SidebarItem
					to={Routes.Addons}
					selected={location.pathname === Routes.Addons}
					icon={<MiscellaneousServicesIcon sx={{ color: "#fff" }} />}
					label="Semi Remolques"
				/>
			)}

			{user?.enterprise.managesPassengers && (
				<SidebarItem
					to={Routes.Passengers}
					selected={location.pathname === Routes.Passengers}
					icon={<PeopleIcon sx={{ color: "#fff" }} />}
					label="Pasajeros"
				/>
			)}

			{user?.enterprise.managesCustomers && (
				<SidebarItem
					to={Routes.Customers}
					selected={location.pathname === Routes.Customers}
					icon={<BusinessIcon sx={{ color: "#fff" }} />}
					label="Clientes"
				/>
			)}

			{user?.enterprise.managesSpots && (
				<SidebarItem
					to={Routes.Spots}
					selected={location.pathname === Routes.Spots}
					icon={<LocationOnIcon sx={{ color: "#fff" }} />}
					label="Puntos"
				/>
			)}

			<Divider sx={{ my: 1, backgroundColor: "rgba(255,255,255,0.2)" }} />

			<ListItemButton
				onClick={() => {
					store.dispatch({ type: AuthActions.SetStoreData, payload: null });
					store.dispatch({ type: AuthActions.DelToken, payload: null });
				}}
				sx={{
					"&:hover": {
						backgroundColor: theme.palette.secondary.main,
						transition: "background-color 0.3s ease",
					},
				}}
			>
				<ListItemIcon>
					<LogoutIcon sx={{ color: "#fff" }} />
				</ListItemIcon>
				<ListItemText primary="Cerrar sesión" />
			</ListItemButton>
		</List>
	);

	if (isMobile) {
		return (
			<>
				<IconButton
					onClick={() => setMobileOpen(true)}
					sx={{
						position: "fixed",
						top: theme.spacing(1),
						left: theme.spacing(1),
						zIndex: theme.zIndex.drawer + 1,
						color: theme.palette.primary.main,
					}}
				>
					<MenuIcon />
				</IconButton>
				<SidebarDrawer
					variant="temporary"
					anchor="left"
					open={mobileOpen}
					onClose={() => setMobileOpen(false)}
					ModalProps={{ keepMounted: true }}
				>
					{drawerContent}
				</SidebarDrawer>
			</>
		);
	}

	return (
		<SidebarDrawer variant="permanent" anchor="left">
			{drawerContent}
		</SidebarDrawer>
	);
};

export default Sidebar;
